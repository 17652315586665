<template>
  <b-overlay :show="isLoading" variant="transparent" opacity="0.99" blur="5px" rounded="sm">
    <template #overlay>
      <div class="text-center">
        <b-icon-controller font-scale="3" animation="cylon" />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card no-body>
      <b-card-title>
        <div class="p-2 d-flex justify-content-between">
          <span>ทัวร์นาเมนต์</span>
          <CreateModal @submit="upsert" :gameOptions="gameOptions" />
        </div>
      </b-card-title>
      <b-table-simple striped hover small responsive show-empty class="mt-2 position-relative items-center"
        :per-page="perPage" :fields="fields" fixed>
        <b-thead>
          <b-tr>
            <b-th>แบนเนอร์</b-th>
            <b-th>ชื่อ</b-th>
            <b-th>สถานะ</b-th>
            <b-th>วันที่สิ้นสุด</b-th>
            <b-th>#</b-th>
          </b-tr>
        </b-thead>
        <b-tr v-for="item in tournaments" :key="item.id">
          <b-td>
            <img v-if="item.banner && agent" :src="`${agent.endpoint.slice(0, -4)}/img/tournaments/${item.banner}`"
              alt="Image" width="150">
          </b-td>
          <b-td>{{ item.name }}</b-td>
          <b-td><b-form-checkbox v-model="item.status" name="check-button" switch @change="changeStatus(item)" /></b-td>
          <b-td>{{ item.close_at | dateFormat }}</b-td>
          <b-td><b-dropdown id="dropdown-3" text="Success" variant="success">
              <template #button-content>
                <i class="far fa-edit mr-50" /> จัดการ
              </template>

              <!-- <Edit :data="item" :game-categories="gameCategories" :game-products="gameProducts" :games="games"
                @getGames="getGames" @getProducts="getGameProducts" @submit="upsert" /> -->
              <b-dropdown-item @click="destroy(item.id)"><i class="fas fa-trash mr-1 text-danger" /> ลบ
              </b-dropdown-item>
            </b-dropdown></b-td>
        </b-tr>
      </b-table-simple>
    </b-card>
    <EditModal @submit="upsert" :gameOptions="gameOptions" :editModal="editModal" :form="form"
      @setModal="editModal = $event" @addQuest="addQuest" />
  </b-overlay>
</template>

<script>
import {
  BFormCheckbox, BTableSimple, BTr, BTd, BTh, BThead, BListGroup, BListGroupItem, BCardTitle, BIcon, BDropdownItem, BDropdown, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody,

} from 'bootstrap-vue'
import moment from 'moment-timezone'
import flatPickr from 'vue-flatpickr-component';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js'
import { Thai as TH } from 'flatpickr/dist/l10n/th';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/plugins/monthSelect/style.css'
import CreateModal from './createModal.vue'
import EditModal from './editModal.vue'

export default {
  components: {
    BFormCheckbox, BTableSimple, BTr, BTd, BTh, BThead, BListGroup, BListGroupItem, BCardTitle,
    BIcon,
    BDropdownItem,
    BDropdown,
    BBadge,
    BOverlay,
    BIconController,
    BInputGroupPrepend,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BButton,
    BCardBody,
    CreateModal,
    flatPickr,
    EditModal,
  },
  filters: {
    dateFormat(val) {
      return moment(val).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm')
    },
  },
  // props: ['items'],
  // emits: [],
  // filters: {
  //   convertDate(date) {
  //     return moment(date).tz('Asia/Bangkok').format('DD/MM/YYYY HH:ss')
  //   },
  // },
  data: () => ({
    isLoading: false,
    fields: [
      { key: 'index', label: 'ลำดับ' },
      { key: 'reward_type', label: 'ประเภท' },
      { key: 'reward', label: 'รางวัล' },
      { key: 'turnover', label: 'Turnover' },
      { key: 'start', label: 'เริ่ม' },
      { key: 'end', label: 'หมดอายุ' },
      { key: 'amount', label: 'จำนวนที่เหลือ' },
      { key: 'actions', label: '#' },
    ],
    gameOptions: [],
    data: null,
    currentPage: 1,
    coupons: [],
    couponTypes: [],
    month: new Date(),
    editModal: false,
    form: {},
    selectMonth: `${moment().month() + 1}/${moment().year()}`,
    monthSelect: {
      locale: TH,
      plugins: [
        new monthSelectPlugin({
          shorthand: true,
          dateFormat: "m/Y",
        })
      ]
    },
    tournaments: []
  }),
  mounted() {
    // this.getCoupons()
    // this.getCouponTypes()
    this.getGameOptions();
    this.getTournaments();
  },
  methods: {
    async getTournaments() {
      try {
        const { data } = await this.$http.get('tournaments');

        this.tournaments = data;
      } catch (error) {
        console.log(error)
      }
    },
    addQuest() {
      this.form.quests.push({
        quest_type: 'DEPOSIT',
        quest_product_id: '',
        quest_amount: 0
      })
    },
    edit(form) {
      this.form = form;
      this.editModal = true;
    },
    async getGameOptions() {
      try {
        const { data } = await this.$http.get('daily-quests/game-options');

        this.gameOptions = data;
      } catch (error) {
        console.log(error)
      }
    },
    selectMonthChange(e) {
      const round = `${moment(e[0]).month() + 1}/${moment(e[0]).year()}`;
      this.selectMonth = round;
      this.getQuests(round);
    },
    async upsert(form) {
      const fd = new FormData;

      fd.append('type', form.type);
      fd.append('name', form.name);
      fd.append('rule', form.rule);
      fd.append('chip', form.chip);
      fd.append('limit', form.limit);
      fd.append('product_id', form.product_id);
      fd.append('close_at', form.close_at);
      fd.append('challenge', form.challenge);

      if (form.banner) {
        fd.append('banner', form.banner);
      }

      form.rewards.forEach((item, index) => {
        fd.append(`rewards[${index}][reward_type]`, item.reward_type);
        fd.append(`rewards[${index}][name]`, item.name);
        fd.append(`rewards[${index}][no]`, item.no);
        fd.append(`rewards[${index}][value]`, item.value);

        if (item.image instanceof File) {
          fd.append(`rewards[${index}][image]`, item.image);
        }
      });

      this.isLoading = true
      try {
        const { data } = await this.$http.post('/tournaments', fd)

        // this.getQuests(this.selectMonth)
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },
    deleteData(id) {
      this.$swal({
        title: 'การยืนยันการลบช้อมูล?',
        text: "คุณต้องการลบภารกิจนี้ใช่หรือไม่",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.isConfirmed) {
          this.isLoading = true;
          try {
            await this.$http.delete(`/daily-quests/${id}`, {
              params: {
                month: this.selectMonth
              }
            })

            this.getQuests(this.selectMonth)
          } catch (e) {
            console.log(e)
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    async submit(form) {
      console.log(form);
      // this.isLoading = true;
      // try {
      //   const { data } = await this.$http.post('/function/coupon/list', form);

      //   this.getCoupons();
      // } catch (e) {
      //   console.log(e);
      // } finally {

      //   this.isLoading = false;
      // }
    },
  },
}
</script>
